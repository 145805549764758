import { Box, Button, ButtonGroup, Divider, Typography } from '@material-ui/core';
import React from 'react';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import CloseIcon from '@material-ui/icons/Close';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useHistory } from 'react-router';
import { hideModal } from './Modal/Modal';

const UnresolvedPrompt = () => {
  const history = useHistory();

  const handleRedirect = () => {
    history.replace('/orders?unresolved=1');
    hideModal();
  }
  return (
    <div>
      <Typography
        component='h3'
        variant='h5'
        style={{
          textAlign: 'center',
          fontWeight: 500,
        }}
      >
        <InfoOutlinedIcon color='primary' /> Ima nezavršenih porudžbina
      </Typography>
      <br />
      <Divider />
      <br />
      <Box>
        <ButtonGroup
          aria-label="outlined primary button group"
          fullWidth
        >
          <Button
            variant='text'
            onClick={handleRedirect}
            endIcon={<ArrowRightAltIcon />}
          >
            Pogledaj
          </Button>
          <Button
            variant='text'
            onClick={hideModal}
            endIcon={<CloseIcon />}
          >
            Zatvori
          </Button>
        </ButtonGroup>
      </Box>
    </div>
  );
};

export default UnresolvedPrompt;