import { Box, Button, Divider, Typography } from '@material-ui/core';
import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { hideModal } from './Modal/Modal';

const EnableSounds = ({setClicked}) => {
  const handleClick = () => {
    setClicked(true);
    hideModal();
  }
  return (
    <div>
      <Typography
        component='h3'
        variant='h6'
        style={{
          textAlign: 'center',
          fontWeight: 500,
        }}
      >
        <InfoOutlinedIcon color='primary' /> Zvučna obaveštenja za porudžbine su uključena
      </Typography>
      <br />
      <Divider />
      <br />
      <Box>
          <Button
            variant='text'
            onClick={handleClick}
            endIcon={<CheckIcon />}
            fullWidth
          >
            U redu
          </Button>
      </Box>
    </div>
  );
};

export default EnableSounds;