import axios from "axios";
import { put } from "redux-saga/effects";
import { notify } from "../../components/ui/Notification/Notification";
import { API_URL } from "../../config";
import * as actionCreators from "../actions/index";

export function* fetchSettingsSaga(action) {
  yield put(actionCreators.fetchSettingsStart());

  const { jwt, callback } = action;
  try {
    const res = yield axios.get(`${API_URL}/settings/read.php`, {
      headers: {
        Authorization: jwt,
      },
    });

    const settings = res.data[0].data[0];

    yield put(actionCreators.fetchSettingsSuccess(settings));

    if (callback) yield callback();
  } catch (error) {
    yield notify("Greška u konekciji", "Danger");
    yield put(actionCreators.fetchSettingsFailed(error));
  }
}

export function* updateSettingsSaga(action) {
  const { jwt, data } = action;

  try {
    yield put(actionCreators.updateSettingsStart());

    yield axios.put(`${API_URL}/settings/update.php`, data, {
      headers: {
        Authorization: jwt,
      },
    });

    yield put(actionCreators.updateSettingsSuccess());
    notify("Izmena sačuvana!", "Success");

    if (action.callback) yield action.callback();
  } catch (error) {
    console.log(error);
    notify("Došlo je do greške!", "Danger");
    yield put(actionCreators.updateSettingsFailed(error));
  }
}
