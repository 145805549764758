import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import { amber, blueGrey, grey } from '@material-ui/core/colors';

export const theme = createMuiTheme({
  palette: {
    primary: amber,
    background: {
      dark: blueGrey[900],
    },
    secondary: grey
  },
  typography: {
    fontFamily: 'Quicksand',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700
  }
})