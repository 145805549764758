import axios from 'axios';
import { put } from 'redux-saga/effects';
import { notify } from '../../components/ui/Notification/Notification';
import { API_URL } from '../../config';
import { transformFirebaseObject } from '../../utility';
import * as actionCreators from '../actions/index';

export function* fetchDependenciesSaga(action) {
  try {
    yield put(actionCreators.fetchDependenciesStart());
    const catRes = yield axios(`${API_URL}product/categoriy_read_all.php`);
    const categories = yield transformFirebaseObject(catRes.data);

    yield put(actionCreators.fetchDependenciesStart());
    const ingRes = yield axios(`${API_URL}/`);
    const ings = yield transformFirebaseObject(ingRes.data);

    const dependencies = { categories, ings };
    yield put(actionCreators.fetchDependenciesSuccess(dependencies));

    if (action.callback)
      yield action.callback();
  } catch (error) {
    yield notify('Greška u konekciji', 'Danger');
    yield put(actionCreators.fetchDependenciesFailed(error));
  }
}