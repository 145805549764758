import * as actionTypes from './actionTypes';

// DEPENDENCIES
export const fetchDependencies = (callback) => { // SAGA
  return {
      type: actionTypes.FETCH_DEPENDENCIES,
      callback
  }
}
export const fetchDependenciesStart = () => { // SAGA
  return {
      type: actionTypes.FETCH_DEPENDENCIES_START,
  }
}
export const fetchDependenciesSuccess = (dependencies) => { // SAGA
  return {
      type: actionTypes.FETCH_DEPENDENCIES_SUCCESS,
      dependencies
  }
}
export const fetchDependenciesFailed = (error) => { // SAGA
  return {
      type: actionTypes.FETCH_DEPENDENCIES_FAILED,
      error
  }
}