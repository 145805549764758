import * as actionTypes from './actionTypes';

// LOGIN
export const login = (loginData, callback) => {
    return {
        type: actionTypes.LOGIN,
        loginData,
        callback
    };
}
export const loginStart = () => {
    return {
        type: actionTypes.LOGIN_START
    };
}
export const loginSuccess = (jwt, id, email, name, admin_type_id, admin_type_name) => {
    return {
        type: actionTypes.LOGIN_SUCCESS,
        jwt,
        id,
        email,
        name,
        admin_type_id,
        admin_type_name
    };
}
export const loginFailed = (error) => {
    return {
        type: actionTypes.LOGIN_FAILED,
        error
    };
}

// CHECK IF LOGGED IN ON LOAD
export const authCheckState = () => {
    return {
        type: actionTypes.AUTH_CHECK_STATE,
    }
}
export const setLogoutTimeout = (expirationTime) => {
    return {
        type: actionTypes.SET_LOGOUT_TIMEOUT,
        expirationTime
    }
}

// LOGOUT
export const logout = () => {
    return {
        type: actionTypes.LOGOUT
    }
}
export const logoutSuccess = () => {
    return {
        type: actionTypes.LOGOUT_SUCCESS
    }
}
