import * as actionTypes from "../actions/actionTypes";

const initialState = {
  settings: null,
  settingsLoading: false,
  settingsError: false,

  updateSettingsLoading: false,
  updateSettingsError: false,
};

const fetchSettingsStart = (state, action) => {
  return {
    ...state,
    settingsLoading: true,
    settingsError: false,
  };
};
const fetchSettingsSuccess = (state, action) => {
  const { settings } = action;

  return {
    ...state,
    settings,
    settingsLoading: false,
    settingsError: false,
  };
};
const fetchSettingsFailed = (state, action) => {
  return {
    ...state,
    settingsLoading: false,
    settingsError: true,
  };
};

const updateSettingsStart = (state, action) => {
  return {
    ...state,
    updateSettingsLoading: true,
    updateSettingsError: false,
  };
};
const updateSettingsSuccess = (state, action) => {
  return {
    ...state,
    updateSettingsLoading: false,
    updateSettingsError: false,
  };
};
const updateSettingsFailed = (state, action) => {
  return {
    ...state,
    updateSettingsLoading: false,
    updateSettingsError: true,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_STATE:
      return initialState;

    case actionTypes.FETCH_SETTINGS_START:
      return fetchSettingsStart(state, action);
    case actionTypes.FETCH_SETTINGS_SUCCESS:
      return fetchSettingsSuccess(state, action);
    case actionTypes.FETCH_SETTINGS_FAILED:
      return fetchSettingsFailed(state, action);

    case actionTypes.UPDATE_SETTINGS_START:
      return updateSettingsStart(state, action);
    case actionTypes.UPDATE_SETTINGS_SUCCESS:
      return updateSettingsSuccess(state, action);
    case actionTypes.UPDATE_SETTINGS_FAILED:
      return updateSettingsFailed(state, action);

    default:
      return state;
  }
};

export default reducer;
