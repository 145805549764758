import * as actionTypes from './actionTypes';

export const fetchSettings = (jwt, callback) => { // SAGA
  return {
      type: actionTypes.FETCH_SETTINGS,
      jwt,
      callback
  }
}
export const fetchSettingsStart = () => { // SAGA
  return {
      type: actionTypes.FETCH_SETTINGS_START,
  }
}
export const fetchSettingsSuccess = (settings) => { // SAGA
  return {
      type: actionTypes.FETCH_SETTINGS_SUCCESS,
      settings,
  }
}
export const fetchSettingsFailed = (error) => { // SAGA
  return {
      type: actionTypes.FETCH_SETTINGS_FAILED,
      error
  }
}

export const updateSettings = (jwt, data, callback) => { // SAGA
  return {
      type: actionTypes.UPDATE_SETTINGS,
      jwt,
      data,
      callback
  }
}
export const updateSettingsStart = () => { // SAGA
  return {
      type: actionTypes.UPDATE_SETTINGS_START,
  }
}
export const updateSettingsSuccess = () => { // SAGA
  return {
      type: actionTypes.UPDATE_SETTINGS_SUCCESS,
  }
}
export const updateSettingsFailed = (error) => { // SAGA
  return {
      type: actionTypes.UPDATE_SETTINGS_FAILED,
      error
  }
}