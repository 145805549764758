import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { NavContextProvider } from './context/nav-context';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { Provider } from 'react-redux';

import dependenciesReducer from './store/reducers/dependencies';
import ordersReducer from './store/reducers/orders';
import authReducer from './store/reducers/auth';
import settingsReducer from './store/reducers/settings';

import { 
  watchDependencies, 
  watchOrders, 
  watchAuth, 
  watchSettings 
} from './store/sagas';

let composeEnhancers = null;
if (process.env.NODE_ENV === 'development') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
  composeEnhancers = compose;
}
const rootReducer = combineReducers({
  dependencies: dependenciesReducer,
  orders: ordersReducer,
  auth: authReducer,
  settings: settingsReducer,
});
const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)))
sagaMiddleware.run(watchDependencies);
sagaMiddleware.run(watchOrders);
sagaMiddleware.run(watchAuth);
sagaMiddleware.run(watchSettings);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <NavContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </NavContextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
