import * as actionTypes from '../actions/actionTypes';

const initialState = {
  dependencies: {
    categories: [],
    ings: []
  },
  dependenciesLoading: false,
  dependenciesError: false,
}

const fetchDependenciesStart = (state, action) => {
  return {
    ...state,
    dependenciesLoading: true,
    dependenciesError: false,
  }
}

const fetchDependenciesSuccess = (state, action) => {
  const { dependencies } = action;
  return {
    ...state,
    dependencies,
    dependenciesLoading: false,
    dependenciesError: false,
  }
}
const fetchDependenciesFailed = (state, action) => {
  return {
    ...state,
    dependenciesLoading: false,
    dependenciesError: true,
  }
}



const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_STATE: return initialState;

    case actionTypes.FETCH_DEPENDENCIES_START: return fetchDependenciesStart(state, action);
    case actionTypes.FETCH_DEPENDENCIES_SUCCESS: return fetchDependenciesSuccess(state, action);
    case actionTypes.FETCH_DEPENDENCIES_FAILED: return fetchDependenciesFailed(state, action);

    default: return state;
  }
}

export default reducer;