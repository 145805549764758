import { Badge, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { useLocation } from "react-router";
// import SpeakerNotesOutlinedIcon from '@material-ui/icons/SpeakerNotesOutlined';
// import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import PostAddOutlinedIcon from '@material-ui/icons/PostAddOutlined';
import { Link } from "react-router-dom";
import { useContext } from "react";
import NavContext from "../../context/nav-context";
import { connect } from "react-redux";

const drawerWidth = 250;

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    backgroundColor: theme.palette.background.dark,
    color: theme.palette.primary.main,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.background.dark,
  },
  listItem: {
    transformOrigin: 'center center',
    position: 'relative',
    '&::before': {
      content: '""',
      display: 'block',
      width: 2,
      transition: 'transform 250ms ease',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      backgroundColor: '#fff',
      transform: 'scaleY(0)',
    }
  },
  active: {
    color: '#fff',
    '&::before': {
      transform: 'scaleY(1)',
    }
  },
  title: {
    padding: theme.spacing(2),
    color: '#fff',
  },
  divider: {
    backgroundColor: theme.palette.primary.main
  },
  badge: {
    left: '100%',
    top: '50%'
  }
}));

const menuItems = [
  // {
  //   id: 1,
  //   text: 'Profil',
  //   icon: <SpeakerNotesOutlinedIcon color='primary' />,
  //   path: '/',
  // },
  // {
  //   id: 2,
  //   text: 'Proizvodi',
  //   icon: <ListAltOutlinedIcon color='primary' />,
  //   path: '/products',
  // },
  {
    id: 3,
    text: 'Porudžbine',
    icon: <PostAddOutlinedIcon color='primary' />,
    path: '/orders',
  }
];

const MyDrawer = ({ unresolvedCount, isAuthenticated }) => {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const navContext = useContext(NavContext);

  // jsx
  const drawerHeader = <div>
    <Typography
      variant='h6'
      className={classes.title}
    >
      Menadžer porudžbina
    </Typography>
    <Divider classes={{ root: classes.divider }} />
  </div>;

  const list = (
    <div className={classes.drawer}>
      <List disablePadding>
        {menuItems.map(item => (
          <ListItem
            key={item.id}
            button
            component={Link}
            to={item.path}
            className={`
              ${(
                  (location.pathname.startsWith(item.path) && item.path.length > 1) ||
                  (location.pathname === '/' && location.pathname === item.path)
                )
                ? classes.active : ''} 
              ${classes.listItem}
            `}
            onClick={() => navContext.setExpanded(false)}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            {item.path === '/orders' ?
              <Badge
                color="primary"
                badgeContent={unresolvedCount}
                classes={{
                  badge: classes.badge
                }}
              >
                <ListItemText primary={item.text} />
              </Badge> :
              <ListItemText primary={item.text} />
            }
          </ListItem>
        ))}
      </List>
    </div>
  );

  let permanentDrawer =
    <Drawer
      className={classes.drawer}
      variant='permanent'
      anchor='left'
      classes={{ paper: classes.drawerPaper }}
      PaperProps={{ elevation: 5 }}
    >
      {drawerHeader}
      {list}
    </Drawer>;
  if (isSmallScreen)
    permanentDrawer = null;

  const drawer = <Drawer
    anchor='left'
    open={navContext.isExpanded}
    onClose={() => navContext.setExpanded(false)}
    classes={{ paper: classes.drawerPaper }}
  >
    {drawerHeader}
    {list}
  </Drawer>;

  return (
    isAuthenticated ?
      <div>
        {permanentDrawer}
        {drawer}
      </div> : null
  );
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.jwt !== null,
    unresolvedCount: state.orders.unresolvedCount,
  }
}

export default connect(mapStateToProps)(MyDrawer);
