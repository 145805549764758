export let host ;
if (process.env.NODE_ENV === 'development') {
    host = 'http://localhost';
} else {
    host = 'https://barrique.dostavau.rs';
}

export let API_URL ;
if (process.env.NODE_ENV === 'development') {
    API_URL = 'http://localhost/order_manager/server/api';
} else {
    API_URL = 'https://barrique.dostavau.rs/api';
}