// RESET WHOLE STATE ON LOGOUT
export const RESET_STATE = 'RESET_STATE';

// AUTH
export const LOGIN = 'LOGIN';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const AUTH_CHECK_STATE = 'AUTH_CHECK_STATE';
export const SET_LOGOUT_TIMEOUT = 'SET_LOGOUT_TIMEOUT';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

// DEPENDENCIES
export const FETCH_DEPENDENCIES = 'FETCH_DEPENDENCIES';
export const FETCH_DEPENDENCIES_START = 'FETCH_DEPENDENCIES_START';
export const FETCH_DEPENDENCIES_SUCCESS = 'FETCH_DEPENDENCIES_SUCCESS';
export const FETCH_DEPENDENCIES_FAILED = 'FETCH_DEPENDENCIES_FAILED';

// ORDERS
export const FETCH_UNRESOLVED = 'FETCH_UNRESOLVED';
export const FETCH_UNRESOLVED_START = 'FETCH_UNRESOLVED_START';
export const FETCH_UNRESOLVED_SUCCESS = 'FETCH_UNRESOLVED_SUCCESS';
export const FETCH_UNRESOLVED_FAILED = 'FETCH_UNRESOLVED_FAILED';

export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_ORDERS_START = 'FETCH_ORDERS_START';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAILED = 'FETCH_ORDERS_FAILED';

export const UPDATE_ORDER = 'UPDATE_ORDER';
export const UPDATE_ORDER_START = 'UPDATE_ORDER_START';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAILED = 'UPDATE_ORDER_FAILED';

// SETTINGS
export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const FETCH_SETTINGS_START = 'FETCH_SETTINGS_START';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_FAILED = 'FETCH_SETTINGS_FAILED';

export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const UPDATE_SETTINGS_START = 'UPDATE_SETTINGS_START';
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
export const UPDATE_SETTINGS_FAILED = 'UPDATE_SETTINGS_FAILED';