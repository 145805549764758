import { takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../actions/actionTypes';

import {
  loginSaga,
  authCheckStateSaga,
  logoutSaga,
  setLogoutTimeoutSaga,
} from './auth';

import {
  fetchDependenciesSaga
} from './dependencies';

import {
  fetchUnresolvedSaga,
  fetchOrdersSaga,
  updateOrderSaga,
} from './orders';

import {
  fetchSettingsSaga,
  updateSettingsSaga,
} from './settings';

export function* watchAuth() {
  yield takeEvery(actionTypes.LOGIN, loginSaga);
  yield takeEvery(actionTypes.SET_LOGOUT_TIMEOUT, setLogoutTimeoutSaga);
  yield takeEvery(actionTypes.AUTH_CHECK_STATE, authCheckStateSaga);
  yield takeEvery(actionTypes.LOGOUT, logoutSaga);
}

export function* watchDependencies() {
  yield takeEvery(actionTypes.FETCH_DEPENDENCIES, fetchDependenciesSaga);
}

export function* watchOrders() {
  yield takeEvery(actionTypes.FETCH_UNRESOLVED, fetchUnresolvedSaga);
  yield takeEvery(actionTypes.FETCH_ORDERS, fetchOrdersSaga);
  yield takeEvery(actionTypes.UPDATE_ORDER, updateOrderSaga);
}

export function* watchSettings() {
  yield takeEvery(actionTypes.FETCH_SETTINGS, fetchSettingsSaga);
  yield takeEvery(actionTypes.UPDATE_SETTINGS, updateSettingsSaga);
}