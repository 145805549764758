import { delay, put } from 'redux-saga/effects';
import * as actionCreators from '../actions/index';
import axios from 'axios';
import { API_URL } from '../../config';
import { notify } from './../../components/ui/Notification/Notification';
import { parseJwt } from '../../utility';

export function* loginSaga(action) {
    yield put(actionCreators.loginStart());

    try {
        const response = yield axios.post(`${API_URL}/auth/login.php`, action.loginData);
        const expirationDate = yield new Date(response.data.expireAt * 1000);
        let jwt = null;
        if (response.data.jwt) {
            jwt = `Bearer ${response.data.jwt}`;
            const { id, email, name, admin_type_id, admin_type_name } = response.data;

            yield localStorage.setItem('jwt', jwt);
            yield localStorage.setItem('expirationDate', expirationDate);
            yield localStorage.setItem('id', id);
            yield localStorage.setItem('email', email);
            yield localStorage.setItem('name', name);
            // yield localStorage.setItem('admin_type_id', admin_type_id);
            yield localStorage.setItem('admin_type_name', admin_type_name);

            yield put(actionCreators.loginSuccess(jwt, id, email, name, admin_type_id, admin_type_name));
            yield put(actionCreators.setLogoutTimeout(expirationDate.getTime() - new Date().getTime()));

            //callback
            if (action.callback)
                yield action.callback();
        } else {
            notify(response.data.message, "Danger");
        }
    } catch (error) {
        notify("Greška u konekciji", "Danger");
        yield put(actionCreators.loginFailed(error));
    }
}

export function* setLogoutTimeoutSaga(action) {
    yield delay(action.expirationTime);
    yield put(actionCreators.logout());
}

export function* authCheckStateSaga(action) {
    const jwt = yield localStorage.getItem('jwt');
    if (!jwt) {
        yield put(actionCreators.logout());// or just return
    } else {
        const token = jwt.split(' ')[1];
        const parsedToken = parseJwt(token);
        
        const expirationDate = yield new Date(localStorage.getItem('expirationDate'));
        if (expirationDate > new Date()) {
            const id = yield localStorage.getItem('id');
            const name = yield localStorage.getItem('name');
            const email = yield localStorage.getItem('email');
            const admin_type_id = yield parsedToken.data.admin_type_id;
            const admin_type_name = yield localStorage.getItem('admin_type_name');

            yield put(actionCreators.loginSuccess(jwt, id, email, name, admin_type_id, admin_type_name));
            yield put(actionCreators.setLogoutTimeout(expirationDate.getTime() - new Date().getTime()));
        } else {
            yield put(actionCreators.logout());
        }
    }
}

export function* logoutSaga(action) {
    yield localStorage.removeItem('jwt');
    yield localStorage.removeItem('expirationDate');
    yield localStorage.removeItem('id');
    yield localStorage.removeItem('name');
    yield localStorage.removeItem('email');
    // yield localStorage.removeItem('admin_type_id');
    yield localStorage.removeItem('admin_type_name');

    yield put(actionCreators.resetState());
    yield put(actionCreators.logoutSuccess());
}
