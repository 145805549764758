import { makeStyles } from "@material-ui/core";
import React from "react";
import MyDrawer from "./MyDrawer";
import MyAppBar from "./MyAppBar";
import Notification from "../ui/Notification/Notification";
import Modal from "../ui/Modal/Modal";


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  page: {
    background: '#f9f9f9',
    width: '100%',
    minHeight: 'calc(100vh - 64px)',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  date: {
    flexGrow: 1
  },
}));

const Layout = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MyAppBar />
      <MyDrawer />
      <div className={classes.page}>
        <div className={classes.toolbar} />
        {props.children}
      </div>
      <Notification />
      <Modal />
    </div>
  );
};

export default Layout;