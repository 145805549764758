import { createContext, useState } from "react";

const NavContext = createContext({
  isExpanded: false,
  toggleExpanded: () => {},
  setExpanded: exp => {},

  pageName: '',
  setPageName: pageName => {}
});

export const NavContextProvider = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [pageName, setPageName] = useState('')

  const toggleExpandedHandler = () => {
    setExpanded(prevExpanded => !prevExpanded)
  }
  const setExpandedHandler = exp => {
    setExpanded(exp)
  }

  const setPageNameHandler = pageName => setPageName(pageName)



  const context = {
    isExpanded: expanded,
    toggleExpanded: toggleExpandedHandler,
    setExpanded: setExpandedHandler,

    pageName,
    setPageName: setPageNameHandler,
  };

  return <NavContext.Provider value={context}>
    {props.children}
  </NavContext.Provider>
}

export default NavContext;