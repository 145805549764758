import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import '@fontsource/roboto';
import { ThemeProvider } from '@material-ui/core';
import Layout from './components/layout/Layout';
import { connect } from 'react-redux';
import * as actions from './store/actions/index';
import React, { useCallback, useEffect, useState } from 'react';
import { showModal } from './components/ui/Modal/Modal';
import useSound from 'use-sound';
import notifSound from './assets/audio/notif2.mp3';
import { theme } from './theme';
import AsyncComponent from './hoc/AsyncComponent';
import UnresolvedPrompt from './components/ui/UnresolvedPrompt';
import EnableSounds from './components/ui/EnableSounds';

const AsyncSettings = AsyncComponent(() => {
  return import('./pages/Settings')
});
const AsyncLogin = AsyncComponent(() => {
  return import('./pages/Login')
});
const AsyncOrders = AsyncComponent(() => {
  return import('./pages/Orders')
});
const AsyncSingleOrder = AsyncComponent(() => {
  return import('./pages/SingleOrder')
});
// const AsyncProducts = AsyncComponent(() => {
//   return import('./pages/Products')
// });


function App({ 
  onAuthCheckState, 
  onFetchUnresolved, 
  onFetchSettings,
  unresolvedCount, 
  unresolvedLoading, 
  onLogout, 
  jwt, 
  isAuthenticated 
}) {
  const [playSound] = useSound(notifSound, { volume: 1 });
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    onAuthCheckState();

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthenticated && !clicked) {
      setTimeout(() => {
          showModal(<EnableSounds setClicked={setClicked} />);
      }, 1000);
    }

    let interval;
    if (isAuthenticated && clicked) {
      onFetchUnresolved(jwt);
      onFetchSettings(jwt);

      interval = setInterval(() => {
        onFetchUnresolved(jwt);
      }, 6000);
    }

    return () => clearInterval(interval);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, clicked]);

  useEffect(() => {
    if (unresolvedCount > 0 && !unresolvedLoading) {
       showModal(<UnresolvedPrompt />);
       playSound();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unresolvedLoading]);

  const handleStorageChange = useCallback(e => {
    if (e.key === 'jwt' && e.oldValue && !e.newValue) {
      onLogout();
    }
    if (e.key === 'jwt' && !e.oldValue && e.newValue) {
      window.location.reload();
    }
  }, [onLogout]);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Layout>
          <Switch>
            <Route exact path="/">
              <AsyncSettings />
            </Route>
            <Route exact path="/orders">
              <AsyncOrders />
            </Route>
            <Route exact path="/orders/:id">
              <AsyncSingleOrder />
            </Route>
            <Route exact path="/login">
              <AsyncLogin />
            </Route>
            {/* <Route exact path="/products">
              <AsyncProducts />
            </Route> */}
            <Redirect to='/' />
          </Switch>
        </Layout>
      </Router>
    </ThemeProvider>
  );
}

const mapStateToProps = state => {
  return {
    jwt: state.auth.jwt,
    isAuthenticated: state.auth.jwt !== null,
    unresolvedCount: state.orders.unresolvedCount,
    unresolvedLoading: state.orders.unresolvedLoading,
    settings: state.settings.settings
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onFetchDependencies: () => dispatch(actions.fetchDependencies()),
    onAuthCheckState: () => dispatch(actions.authCheckState()),
    onLogout: () => dispatch(actions.logout()),
    onFetchUnresolved: (jwt, callback) => dispatch(actions.fetchUnresolved(jwt, callback)),
    onFetchSettings: (jwt, callback) => dispatch(actions.fetchSettings(jwt, callback)),
  }
}

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(App));