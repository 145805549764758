export const transformFirebaseObject = items => {
  const newItems = [];
  for (const key in items) {
    const item = { id: key, ...items[key] }
    newItems.push(item);
  }

  return newItems;
}

export const checkValidity = (value, rules, passwordToMatch = '') => {
  if (!rules) return true;
  let isValid = true;
  let message = '';

  if (rules.required) {
    isValid = !!value;
    if (!isValid) {
      message = 'Ovo polje je obavezno';
      return { isValid, message }
    }
  }
  return { isValid, message };
}

export const replaceSpecialChars = (str) => {
  let updatedStr;
  if (str && str.length > 0) {
    updatedStr = str.replace(/š/gi, 's');
    updatedStr = updatedStr.replace(/ć/gi, 'c');
    updatedStr = updatedStr.replace(/č/gi, 'c');
    updatedStr = updatedStr.replace(/ž/gi, 'z');
    updatedStr = updatedStr.replace(/đ/gi, 'dj');
    return updatedStr;
  } else {
    return str;
  }
}

export const formatDate = (date) => {
  const formattedDate = new Date(date);
  const mL = ['Januar', 'Februar', 'Mart', 'April', 'Maj', 'Jun', 'Jul', 'Avgust', 'Septembar', 'Oktobar', 'Novembar', 'Decembar'];
  // const mS = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Avg', 'Sep', 'Okt', 'Nov', 'Dec'];

  const day = formattedDate.getDate();
  const month = formattedDate.getMonth();
  const year = formattedDate.getFullYear();
  const hours = formattedDate.getHours();
  let minutes = formattedDate.getMinutes();
  if(minutes < 10) {
    minutes = `0${minutes}`
  }

  let time = '';
  if(hours && minutes) {
    time = ` - ${hours}:${minutes}`
  }
  return `${day}. ${mL[month]} ${year}.${time}`;
}

// add queries to url react-router
export const addQuery = (location, history, key, value) => {
  const pathname = location.pathname;
  const searchParams = new URLSearchParams(location.search);
  searchParams.set(key, value);
  console.log({searchParams: searchParams.toString()})
  console.log({pathname})
  history.push({
    pathname: pathname,
    search: searchParams.toString()
  });
};

export const removeQuery = (location, history, key) => {
  const pathname = location.pathname;
  const searchParams = new URLSearchParams(location.search);
  searchParams.delete(key);
  history.push({
    pathname: pathname,
    search: searchParams.toString()
  });
};

export function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};