import * as actionTypes from './actionTypes';

// ORDERS
export const fetchUnresolved = (jwt, callback) => { // SAGA
  return {
      type: actionTypes.FETCH_UNRESOLVED,
      jwt,
      callback
  }
}
export const fetchUnresolvedStart = () => { // SAGA
  return {
      type: actionTypes.FETCH_UNRESOLVED_START,
  }
}
export const fetchUnresolvedSuccess = (unresolvedCount) => { // SAGA
  return {
      type: actionTypes.FETCH_UNRESOLVED_SUCCESS,
      unresolvedCount
  }
}
export const fetchUnresolvedFailed = (error) => { // SAGA
  return {
      type: actionTypes.FETCH_UNRESOLVED_FAILED,
      error
  }
}

export const fetchOrders = (jwt, searchData, pageNumber = 0, resPerPage = 10, callback) => { // SAGA
  return {
      type: actionTypes.FETCH_ORDERS,
      jwt,
      searchData,
      pageNumber,
      resPerPage,
      callback
  }
}
export const fetchOrdersStart = () => { // SAGA
  return {
      type: actionTypes.FETCH_ORDERS_START,
  }
}
export const fetchOrdersSuccess = (orders, resultsCount, unresolvedCount) => { // SAGA
  return {
      type: actionTypes.FETCH_ORDERS_SUCCESS,
      orders,
      resultsCount,
      unresolvedCount
  }
}
export const fetchOrdersFailed = (error) => { // SAGA
  return {
      type: actionTypes.FETCH_ORDERS_FAILED,
      error
  }
}
export const updateOrder = (jwt, data, callback) => { // SAGA
  return {
      type: actionTypes.UPDATE_ORDER,
      jwt,
      data,
      callback
  }
}
export const updateOrderStart = () => { // SAGA
  return {
      type: actionTypes.UPDATE_ORDER_START,
  }
}
export const updateOrderSuccess = () => { // SAGA
  return {
      type: actionTypes.UPDATE_ORDER_SUCCESS,
  }
}
export const updateOrderFailed = (error) => { // SAGA
  return {
      type: actionTypes.UPDATE_ORDER_FAILED,
      error
  }
}