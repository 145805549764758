import * as actionTypes from '../actions/actionTypes';

const initialState = {
    jwt: null,
    id: null,
    email: null,
    name: null,
    admin_type_id: null,
    admin_type_name: null,
    error: null,
    loading: false,
    authRedirectPath: '/',
}

export const loginStart =(state, action) => {
    return {
        ...state,
        loading: true,
        error: null,
    }
}
export const loginSuccess =(state, action) => {
    return {
        ...state,
        jwt: action.jwt,
        id: action.id,
        email: action.email,
        name: action.name,
        admin_type_id: action.admin_type_id,
        admin_type_name: action.admin_type_name,
        loading: false,
    }
}
export const loginFailed =(state, action) => {
    return {
        ...state,
        error: action.error,
        loading: false
    }
}

export const logoutSuccess = (state, action) => {
    return {
        ...initialState
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESET_STATE: return initialState;
        
        case actionTypes.LOGIN_START: return loginStart(state, action);
        case actionTypes.LOGIN_SUCCESS: return loginSuccess(state, action);
        case actionTypes.LOGIN_FAILED: return loginFailed(state, action);

        case actionTypes.LOGOUT_SUCCESS: return logoutSuccess(state, action);

        default: return state;
    }
}

export default reducer;